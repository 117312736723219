import avatar from "../src/images/avatar.svg"
export const APP_CONFIG = process.env.REACT_APP_ENV === "production" ?
    {
        apiPath: "https://www.politiq.net/api",
        recaptchaSiteKey: "6Lf_iFwUAAAAAKTVxEHwzhxnSPODjMyJVY6a5zMq",
        urlPreviewKey: "59f18cb52153e3908e5cabf60a6fa1494dc08c25f62e0",
        previewApiUrl: "https://api.linkpreview.net",
        defaultAvatar: "https://www.politiq.net/images/people/avatar.png",
        donationApiUrl: "https://pay.quantemplate.ai/api/donate",
        stripePublicKey: "pk_live_51QomHMCV8BsKphzVenKb2GYo8s6FV7biBQSxpK1GJTq4vHKevQ20W7en5pYO9RaoQQc38D4j6qszlppBYhdyefji00kvjUtSZa",
    } : {
        apiPath: "https://dev.politiq.net/api",
        recaptchaSiteKey: "6Lf_iFwUAAAAAKTVxEHwzhxnSPODjMyJVY6a5zMq",
        urlPreviewKey: "59f18cb52153e3908e5cabf60a6fa1494dc08c25f62e0",
        previewApiUrl: "https://api.linkpreview.net",
        defaultAvatar: avatar,
        donationApiUrl: "https://pay.dev.quantemplate.ai/api/donate",
        stripePublicKey: "pk_test_51QomHGEEk8Kb2JSlkksQcBqw3DFLMTJU18Z6HNV3aLu01MkcSPcZC4uFKXhKNiYq8VDP1fzJrbIi3fm8Fc31Rqmj00pK3Sw49D",
        
    }
