import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers/index';
import {Helmet} from "react-helmet";
import {composeWithDevTools} from 'redux-devtools-extension';
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import {ConnectedRouter, routerMiddleware} from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import {promiseMiddleware, localStorageMiddleware} from './middleware/middleware';
import {createLogger} from 'redux-logger'
import swDev from './swDev'
import 'react-rangeslider/lib/index.css';
import 'antd/dist/antd.css';

import Authorizer, {authRoles} from './components/Utils/Authorizer';
import App from './components/App/App';
import Footer from './components/App/Footer'

import "./css/main.css";
import "./css/core.css"

import Loadable from 'react-loadable';
import Loader from "./components/Loader";

const history = createHistory();
// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history);

const loading = () => <Loader/>

//Component Loadable
const Home = Loadable({
  loader: () => import('./components/Home'),
  loading
});

const UserBanned = Loadable({
  loader: () => import('./components/UserBanned'),
  loading
});

const unAuthHome = Loadable({
  loader: () => import('./components/Static/Home'),
  loading
});

const Values = Loadable({
  loader: () => import('./components/Static/Values'),
  loading
});

const MigratingFromTwitter = Loadable({
  loader: () => import('./components/Static/MigratingFromTwitter'),
  loading
});


// const Footer = Loadable({
//   loader: () => import('./components/App/Footer'),
//   loading
// });

const PolliesList = Loadable({
  loader: () => import('./components/Pollies/PolliesList'),
  loading
});

const ClaimMyPage = Loadable({
  loader: () => import('./components/Pollies/ClaimMyPage'),
  loading
});

const Elections = Loadable({
  loader: () => import('./components/Election/Elections'),
  loading
});

const Polly = Loadable({
  loader: () => import('./components/Pollies/Polly'),
  loading
});

const Login = Loadable({
  loader: () => import('./components/Login/Login'),
  loading
});

const FBLogin = Loadable({
  loader: () => import('./components/FBLogin'),
  loading
});

const TWLogin = Loadable({
  loader: () => import('./components/TWLogin'),
  loading
});

const Community = Loadable({
  loader: () => import('./components/Static/Community'),
  loading
});

const ForgotPassword = Loadable({
  loader: () => import('./components/ForgotPassword/ForgotPassword'),
  loading
});

const ResetPassword = Loadable({
  loader: () => import('./components/ForgotPassword/ResetPassword'),
  loading
});

const Register = Loadable({
  loader: () => import('./components/Register/Register'),
  loading
});

const SuccessPage = Loadable({
  loader: () => import('./components/Register/SuccessPage'),
  loading
});

const Editor = Loadable({
  loader: () => import('./components/Editor/Editor'),
  loading
});

const Topic = Loadable({
  loader: () => import('./components/Topic/Topic'),
  loading
});

const TopicStance = Loadable({
  loader: () => import('./components/Topic'),
  loading
});

const Post = Loadable({
  loader: () => import('./components/Post'),
  loading
});

const AboutUs = Loadable({
  loader: () => import('./components/Static/AboutUs'),
  loading
});

const AccountDeleted = Loadable({
  loader: () => import('./components/Static/AccountDeleted'),
  loading
});

const Contact = Loadable({
  loader: () => import('./components/Static/Contact'),
  loading
});

const PrivacyPolicy = Loadable({
  loader: () => import('./components/Static/PrivacyPolicy'),
  loading
});

const Blog = Loadable({
  loader: () => import('./components/Blog/Blog'),
  loading
});

const Blogs = Loadable({
  loader: () => import('./components/Blog/Blogs'),
  loading
});

const SupportUs = Loadable({
  loader: () => import('./components/Static/SupportUs'),
  loading
});

const Sponsor = Loadable({
  loader: () => import('./components/Sponsor/SponsorPage'),
  loading
});

const Terms = Loadable({
  loader: () => import('./components/Static/Terms'),
  loading
});

const MyStuff = Loadable({
  loader: () => import('./components/MyStuff/MyStuff'),
  loading
});

const Settings = Loadable({
  loader: () => import('./components/Settings/Settings'),
  loading
});

const GoalsList = Loadable({
  loader: () => import('./components/Goals/GoalsList'),
  loading
});

const Goals = Loadable({
  loader: () => import('./components/Goals'),
  loading
});

const Orgs = Loadable({
  loader: () => import("./components/Orgs"),
  loading
});

const EditOrganisation = Loadable({
  loader: () => import("./components/Orgs/OrgForm"),
  loading
});

const ViewOrganisation = Loadable({
  loader: () => import("./components/Orgs/OrgDetails"),
  loading
});

const GoalView = Loadable({
  loader: () => import('./components/Goals/GoalView'),
  loading
});

const EmailAndPasswordUpdate = Loadable({
  loader: () => import('./components/UpdateEmailModal'),
  loading
});

const ThankYou = Loadable({
  loader: () => import('./components/Goals/ThankYou'),
  loading
});

const FoundingMembers = Loadable({
  loader: () => import('./components/FoundingMembers/FoundingMembers'),
  loading
});

const Members = Loadable({
  loader: () => import('./components/Members'),
  loading
});

const Audiences = Loadable({
  loader: () => import('./components/Audiences/Audiences'),
  loading
});

const Profile = Loadable({
  loader: () => import('./components/Profile/Profile'),
  loading
});

const ProfileFavorites = Loadable({
  loader: () => import('./components/Profile/ProfileFavorites'),
  loading
});

const Activation = Loadable({
  loader: () => import('./components/Register/Activation'),
  loading
});

const PageLayout = Loadable({
  loader: () => import('./components/Pages/PageLayout'),
  loading
});

const TextTagPage = Loadable({
  loader: () => import('./components/Pages/TextTagPage'),
  loading
});

const LayoutVoteDetails = Loadable({
  loader: () => import('./components/VoteDetails/LayoutVoteDetails'),
  loading
});

const Mission = Loadable({
  loader: () => import('./components/Mission'),
  loading
});

const Features = Loadable({
  loader: () => import('./components/Features'),
  loading
});

const AdminSetting = Loadable({
  loader: () => import('./components/AdminSetting'),
  loading
});

const Unauthorized = Loadable({
  loader: () => import('./components/Unauthorized'),
  loading
});

const InterViews = Loadable({
  loader: () => import('./components/Interviews'),
  loading
});

const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(thunk, promiseMiddleware, localStorageMiddleware, middleware, createLogger())
  )
);

const MainApp = () => (
    <Provider store={store}>
      <ConnectedRouter basename={"/"} forceRefresh={true} history={history}>
        <div>
          <Switch>
            <Route exact path="/user-banned" component={Authorizer(UserBanned, authRoles.user)} />
          </Switch>
          <div className={`ui ${history.pathName != '/' || history.pathName != '/login' || history.pathName != '/user-banned' && 'container'}`}>
            <App>
              <Helmet>
                <title>Politiq</title>
              </Helmet>
              <div id="content" className="ui container">
                <Switch>
                  <Route exact path="/" component={Authorizer(Home, authRoles.user, unAuthHome, true)} />
                  <Route exact path="/pollies" component={Authorizer(PolliesList, authRoles.user)} />
                  <Route path="/Claim_My_Page/:urlSlug" component={Authorizer(ClaimMyPage, authRoles.guest)} />
                  <Route exact path="/elections_near_me" component={Authorizer(Elections, authRoles.user)} />
                  <Route exact path="/polly/:id" component={Authorizer(Polly, authRoles.guest)} />
                  <Route path="/login" component={Authorizer(Login, authRoles.guest)} />
                  <Route path="/fb-login" component={Authorizer(FBLogin, authRoles.guest)} />
                  <Route path="/tw-login" component={Authorizer(TWLogin, authRoles.guest)} />
                  <Route path="/community" component={Authorizer(Community, authRoles.guest)} />
                  <Route path="/forgotPassword" component={Authorizer(ForgotPassword, authRoles.guest)} />
                  <Route path="/resetPassword/:token" component={Authorizer(ResetPassword, authRoles.guest)} />
                  <Route exact path="/register" component={Authorizer(Register, authRoles.guest)} />
                  <Route path="/register/success" component={Authorizer(SuccessPage, authRoles.guest)} />
                  <Route exact path="/editor" component={Authorizer(Editor, authRoles.user)} />
                  <Route path="/editor/:slug" component={Authorizer(Editor, authRoles.user)} />
                  <Route exact path="/topicstances/new/:slug" component={Authorizer(Topic, authRoles.user)} />
                  <Route exact path="/topicstances/edit/:slug" component={Authorizer(Topic, authRoles.user)} />
                  <Route exact path="/topicstance/:slug" component={Authorizer(TopicStance, authRoles.guest)} />
                  <Route path="/posts/:slug" component={Authorizer(Post, authRoles.guest)} />
                  <Route path="/about" component={Authorizer(AboutUs, authRoles.guest)} />
                  <Route path="/migrating-from-twitter" component={Authorizer(MigratingFromTwitter, authRoles.guest)} />
                  <Route path="/politiq-values" component={Authorizer(Values, authRoles.guest)} />
                  <Route path="/accountDeleted" component={Authorizer(AccountDeleted, authRoles.guest)} />
                  <Route path="/contact" component={Authorizer(Contact, authRoles.guest)} />
                  <Route path="/privacy" component={Authorizer(PrivacyPolicy, authRoles.guest)} />
                  <Route exact path="/blog" component={Authorizer(Blog, authRoles.guest)} />
                  <Route exact path="/blog/:id" component={Authorizer(Blogs, authRoles.guest)} />
                  <Route path="/support-us" component={Authorizer(SupportUs, authRoles.guest)} />
                  <Route path="/sponsor" component={Authorizer(Sponsor, authRoles.guest)} />
                  <Route path="/terms" component={Authorizer(Terms, authRoles.guest)} />
                  <Route path="/my-stuff" component={Authorizer(MyStuff, authRoles.user)} />
                  <Route path="/settings" component={Authorizer(Settings, authRoles.user)} />
                  <Route path="/emailandpasswordupdate" component={Authorizer(EmailAndPasswordUpdate, authRoles.user)} />
                  <Route exact path="/goals/thanks" component={Authorizer(ThankYou, authRoles.user)} />
                  <Route exact path="/goals" component={Authorizer(GoalsList, authRoles.user)} />
  <Route exact path="/organisations" component={Authorizer(Orgs, authRoles.guest)} />
                  <Route exact path="/edit_orgs/:slug" component={Authorizer(EditOrganisation, authRoles.user)} />
                  <Route exact path="/create_orgs" component={Authorizer(EditOrganisation, authRoles.user)} />
                  <Route exact path="/create_goal/:slug" component={Authorizer(Goals, authRoles.user)} />``
                  <Route exact path="/edit_goal/:slug" component={Authorizer(Goals, authRoles.user)} />
                  <Route exact path="/goals/:slug" component={Authorizer(GoalView, authRoles.user)} />
                  <Route path="/founding-members" component={Authorizer(FoundingMembers, authRoles.user)} />
                  <Route path="/members" component={Authorizer(Members, authRoles.user)} />
                  <Route path="/audiences" component={Authorizer(Audiences, authRoles.user)} />
                  <Route path="/admin/setting" component={Authorizer(AdminSetting, authRoles.user)} />
                  <Route path="/unauthorized" component={Authorizer(Unauthorized, authRoles.user)} />
                  <Route exact path="/mission" component={Authorizer(Mission, authRoles.guest)} />
                  <Route exact path="/features" component={Authorizer(Features, authRoles.guest)} />
                  <Route exact path="/@:username" component={Authorizer(Profile, authRoles.user)} />
                  <Route path="/@:username/favorites" component={Authorizer(ProfileFavorites, authRoles.user)} />
                  <Route exact path="/activation/:activationKey" component={Authorizer(Activation, authRoles.guest)} />
                  <Route exact path="/:URLslug" component={Authorizer(PageLayout, authRoles.guest)} />
                  <Route exact path="/post_tags/:tag_id" component={Authorizer(TextTagPage, authRoles.guest)} />
                  <Route exact path="/interviews/:URL_Slug" component={Authorizer(InterViews, authRoles.guest)} />
                  <Route exact path="/vote/:urlslug/:username/:date" component={Authorizer(LayoutVoteDetails, authRoles.guest)} />
                  <Route path="*" component={Authorizer(PageLayout, authRoles.guest)} />
                </Switch>

              </div>
            </App>
          </div>
        {
          (window.location.pathname === '/' || window.location.pathname === '/contact' || window.location.pathname === '/mission' || window.location.pathname === '/about' || window.location.pathname === '/features' || window.location.pathname === '/privacy' || window.location.pathname === '/terms' || window.location.pathname === '/register' || window.location.pathname === '/login' || window.location.pathname === '/admin/setting' || window.location.pathname === '/sponsor' ) ? null :  <Footer />
        }
        </div>
      </ConnectedRouter>
    </Provider>
);
swDev();


ReactDOM.render(<MainApp />, document.getElementById('root'));
